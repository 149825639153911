/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import common from '../messages/common';
import ErrorPage from '../components/ErrorPage';

const NotFoundPage = () => {
  const [threshold, setThreshold] = useState(false);

  useEffect(() => {
    setTimeout(() => setThreshold(true), 1500);
  }, []);

  return (
    <Layout>
      {threshold ? (
        <>
          <Seo title={common.seo404Title} />
          <ErrorPage error={404} />
        </>
      ) : (
        <div />
      )}
    </Layout>
  );
};

export default NotFoundPage;
