import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelectWrapper from '../ReactSelectWrapper';
import { defineMessages } from 'react-intl';

export const countrySelectMessages = defineMessages({
  labelCountry: {
    id: 'cc4.form.field.label.country.general',
    defaultMessage: 'Country',
  },
  placeholderCountry: {
    id: 'cc4.form.field.placeholder.country.general',
    defaultMessage: 'Select country',
  },
});

const buildCountryValue = country => ({
  value: country.id,
  label: country.name,
  ...country,
});

const CountrySelect = props => {
  const { code, prefillWithFirstOption, disabled } = props;
  const [countryCode, setCountryCode] = useState(code);

  // if the (portal/code) is not correct and user removes this value
  // we want to clear the filterparams with code
  function onChangeCallback(value) {
    if (value == null) {
      setCountryCode(value);
    }
  }

  function onInputChange() {
    setCountryCode(null);
  }

  return (
    <ReactSelectWrapper
      {...props}
      endpoint="countries"
      filterParams={{
        ...(countryCode ? { code: countryCode } : {}),
      }}
      onChangeCallback={onChangeCallback}
      searchOnFilters={['name']}
      valueConstructor={buildCountryValue}
      autoComplete="off"
      defaultOptions
      prefillWithFirstOption={prefillWithFirstOption}
      openMenuOnClick
      onInputChange={onInputChange}
      disabled={disabled}
    />
  );
};

CountrySelect.defaultProps = {
  isClearable: true,
  label: countrySelectMessages.labelCountry,
  placeholder: countrySelectMessages.placeholderCountry,
  prefillWithFirstOption: true,
};

CountrySelect.propTypes = {
  code: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isClearable: PropTypes.bool,
  label: PropTypes.object,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.object,
  prefillWithFirstOption: PropTypes.bool,
};

export default CountrySelect;
