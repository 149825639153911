import React, { useState } from 'react';
import messages from './messages';
import { useIntl } from 'react-intl';
import PlaneIconSVG from '../../assets/icons/plane-icon.inline.svg';
import arrowDownIcon from '../../assets/icons/arrow-down-bank-details.svg';
import upArrowIcon from '../../assets/icons/upp-arrow-bank-details.svg';
import { CURRENCIES } from '../../components/fields/CurrencyInput/CurrencySelect';

const ClaimFlightInformation = (props: any) => {
  const [isShowMoreOpened, setShowMoreOpened] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const {
    flight,
    compensation_per_passenger,
    passenger_names,
    selling_percentage,
  } = props;
  const currency = CURRENCIES[compensation_per_passenger?.currency]?.symbol;
  const totalCompensationNoFees =
    compensation_per_passenger?.amount * passenger_names.split(', ')?.length;
  const feesAmount = (totalCompensationNoFees * selling_percentage) / 100;
  const receiveAmount = totalCompensationNoFees - feesAmount;
  let imgAirline;
  try {
    imgAirline = require(`../../assets/images/airlines/${flight?.airline?.icao}.svg`);
  } catch (e) {
    imgAirline = require('../../assets/images/airlines/Default.svg');
  }

  return (
    <div>
      <h2 style={{ fontSize: '24px', marginBottom: '12px' }}>
        {formatMessage(messages.claimInfoHeader)}
      </h2>
      <div style={wrapper}>
        <div style={{ display: 'flex' }}>
          <img src={imgAirline} width={24} height={24} />
          <span style={{ marginLeft: '5px', fontSize: '14px' }}>
            {flight?.airline?.name}
          </span>
        </div>
        <div>
          <div style={{ float: 'right', display: 'flex' }}>
            <span>
              <b>{flight?.departureAirport?.iata}</b>
            </span>
            <PlaneIconSVG className="mx-8 inline-block w-20 h-20 fill-primary" />
            <span>
              <b>{flight?.arrivalAirport?.iata}</b>
            </span>
          </div>
        </div>
        <div>
          <span style={{ fontSize: '12px' }}>
            {formatMessage(messages.flightNumberLabel)}:
            <strong>
              {flight?.airline_code}
              {flight?.flight_number}
            </strong>
          </span>
        </div>
        <div>
          <span style={{ float: 'right', fontSize: '12px' }}>
            {formatMessage(messages.flightDateLabel)}:{' '}
            <strong>
              {new Date(
                flight?.scheduled_gate_departure_date
              )?.toLocaleDateString()}
            </strong>
          </span>
        </div>
        {isShowMoreOpened && (
          <>
            <div>
              <span style={{ fontSize: '12px' }}>
                {formatMessage(messages.passengersLabel)}:{' '}
                <strong>{passenger_names}</strong>
              </span>
            </div>
            <div>
              <span style={{ float: 'right', fontSize: '12px' }}>
                {formatMessage(messages.totalLabel)}:{' '}
                <strong>{passenger_names.split(', ')?.length}</strong>
              </span>
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                {formatMessage(messages.totalLabel)}:{' '}
                <strong>
                  {currency}
                  {compensation_per_passenger?.amount}{' '}
                </strong>
                {formatMessage(messages.perPassengerLabel)}
              </span>
            </div>
            <div>
              <span style={{ float: 'right', fontSize: '12px' }}>
                {formatMessage(messages.totalLabel)}:{' '}
                <strong>
                  {currency}
                  {totalCompensationNoFees}{' '}
                </strong>
              </span>
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                {formatMessage(messages.feesLabel)}:{' '}
                <strong>{selling_percentage}%</strong>
              </span>
            </div>
            <div>
              <span style={{ float: 'right', fontSize: '12px' }}>
                {formatMessage(messages.totalLabel)}:{' '}
                <strong>
                  {currency}
                  {feesAmount}
                </strong>
              </span>
            </div>
          </>
        )}
        <div style={{ margin: '12px 0 12px 0' }}>
          <div style={{ display: 'flex' }}>
            <span style={{ fontSize: '12px' }}>
              {formatMessage(
                isShowMoreOpened
                  ? messages.showLessLabel
                  : messages.showMoreLabel
              )}
            </span>
            <img
              src={isShowMoreOpened ? upArrowIcon : arrowDownIcon}
              height={24}
              width={24}
              style={{ cursor: 'pointer' }}
              onClick={() => setShowMoreOpened(!isShowMoreOpened)}
            />
          </div>
        </div>
        <div style={{ margin: '12px 0 12px 0' }}>
          <span style={{ float: 'right', fontSize: '14px' }}>
            {formatMessage(messages.willReceiveLabel)}:{' '}
            <span style={{ color: '#4E9B77', fontWeight: 700 }}>
              <strong>
                {currency}
                {receiveAmount}
              </strong>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ClaimFlightInformation;

const wrapper = {
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  border: '1px solid #D2D2D2',
  borderRadius: '8px',
  padding: '10px',
};
