import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

export const CURRENCIES = {
  EUR: {
    name: 'EUR',
    symbol: '€',
  },
  GBP: {
    name: 'GBP',
    symbol: '£',
  },
  USD: {
    name: 'USD',
    symbol: '$',
  },
  DKK: {
    name: 'DKK',
    symbol: 'Kr.',
  },
  PLN: {
    name: 'PLN',
    symbol: 'zł',
  },
  CHF: {
    name: 'CHF',
    symbol: 'CHf/Fr.',
  },
};

const CurrencySelect = props => {
  const { name, placeholder, field } = props;
  const intl = useIntl();
  const placeholderText = placeholder?.id
    ? intl.formatMessage(placeholder)
    : placeholder;

  return (
    <div className="inline-block relative">
      <select
        name={name}
        placeholder={placeholder}
        className="block appearance-none bg-white focus:outline-none focus:shadow-outline pr-30"
        {...field}
      >
        <option value="" disabled>
          {placeholderText}
        </option>
        {Object.keys(CURRENCIES).map(currency => (
          // eslint-disable-next-line
          <option key={`name-${CURRENCIES[currency].name}`} value={currency}>
            {`${CURRENCIES[currency].name}`}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex justify-center px-2 text-gray-700">
        <svg
          className="fill-current text-primary-400 h-30 w-30"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
};

CurrencySelect.propTypes = {
  field: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default CurrencySelect;
