import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'gatsby';

import Icon from './Icon';
import continueIcon from '../assets/icons/continue-icon.inline.svg';
import errorImage from '../assets/images/error.svg';
import common from '../messages/common';

const ErrorPage = ({ error }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="container w-full mx-auto mdd:max-w-none mt-64 sm:mt-100">
      <div className="mx-20 sm:mx-auto flex flex-col sm:flex-row items-center justify-between">
        <div
          className="w-full sm:w-auto flex-shrink-0 flex align-center
          sm:items-start items-center flex-col"
        >
          <h1 className="text-3xl sm:text-enormous">
            {formatMessage(common.errorPageTitle)}
          </h1>
          <hr className={'separator-thick hidden sm:block mt-45'} />
          <p className="text-3xl sm:text-4xl font-black mt-5 sm:mt-30">
            {formatMessage(
              error === 500
                ? common.errorDescription500
                : common.errorDescription404
            )}
          </p>
          <Link
            theme="secondary-fill"
            to={'/'}
            className="button button-large button-secondary-fill my-50 sm:mb-0 w-full sm:w-260"
          >
            <Icon
              src={continueIcon}
              alt="Continue icon"
              className="inline w-2 h-4 mr-15"
              style={{
                transform: `rotate(180deg)`,
              }}
            />
            {formatMessage(common.backToHome)}
          </Link>
        </div>
        <img
          className="mt-25 sm:mt-0 flex-shrink-0"
          src={errorImage}
          aria-hidden={'true'}
          alt={'Error image'}
        />
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.oneOf([404, 500]),
};

export default ErrorPage;
