import { defineMessages } from 'react-intl';

export default defineMessages({
  bankDetailsTitle: {
    id: 'cc4.bank-details.title',
    defaultMessage: 'Update your bank details',
  },
  bankDetailsSubTitle: {
    id: 'cc4.bank-details.subtitle',
    defaultMessage:
      'Good news! We are almost ready to pay-out your compensation. We only need some additional bank details to finalise the transfer.',
  },
  claimInfoHeader: {
    id: 'cc4.bank-details.claim-info-header',
    defaultMessage: 'Your claim information',
  },
  donateCompensationTitle: {
    id: 'cc4.bank-details.donate-compensation-title',
    defaultMessage: 'Donate some of the compensation?',
  },
  donateCompensationSubTitle: {
    id: 'cc4.bank-details.donate-compensation-sub-title',
    defaultMessage: 'I would like to make a donation of:',
  },
  cardHolderTitle: {
    id: 'cc4.bank-details.card-holder-title',
    defaultMessage: 'Cardholder bank details',
  },
  cardHolderSubTitle: {
    id: 'cc4.bank-details.card-holder-sub-title',
    defaultMessage:
      'Make sure that the cardholder information matches with your card. This can sometimes be different as your personal information.',
  },
  countryBankLabel: {
    id: 'cc4.bank-details.country-bank-label',
    defaultMessage: 'Country of the bank',
  },
  holderLabel: {
    id: 'cc4.bank-details.holder-label',
    defaultMessage: 'Account holder name',
  },
  ibanLabel: {
    id: 'cc4.bank-details.iban-label',
    defaultMessage: 'IBAN',
  },
  holderAddressLabel: {
    id: 'cc4.bank-details.holder-address-label',
    defaultMessage: 'Account holder address',
  },
  cityLabel: {
    id: 'cc4.bank-details.city-label',
    defaultMessage: 'City',
  },
  zipCodeLabel: {
    id: 'cc4.bank-details.zip-code-label',
    defaultMessage: 'Zip Code',
  },
  bankDetails: {
    id: 'cc4.bank-details-header',
    defaultMessage: 'Bank details',
  },
  accountNumberLabel: {
    id: 'cc4.bank-details-account-number-label',
    defaultMessage: 'Account number',
  },
  bankDetailsSubHeader: {
    id: 'cc4.bank-details-sub-header',
    defaultMessage:
      'This is the information from your bank and where the bank is located. We need this information to transfer the compensation',
  },
  bankNameLabel: {
    id: 'cc4.bank-details-name-label',
    defaultMessage: 'Bank name',
  },
  bankCodeLabel: {
    id: 'cc4.bank-details-code-label',
    defaultMessage: 'Bank code',
  },
  bicCodeLabel: {
    id: 'cc4.bank-details-bic-label',
    defaultMessage: 'Bic code',
  },
  bankAddessLabel: {
    id: 'cc4.bank-details-address-label',
    defaultMessage: 'Bank address',
  },
  ibanTitle: {
    id: 'cc4.bank-details-iban-title-tooltip',
    defaultMessage: 'IBAN (International Bank Account Number',
  },
  ibanContent: {
    id: 'cc4.bank-details-iban-content-tooltip',
    defaultMessage:
      'You can find your IBAN number by logging into your online banking platform or by checking your paper bank statement',
  },
  successUpdateDetails: {
    id: 'cc4.bank-details-success-msg',
    defaultMessage: 'You have successfully update your bank details',
  },
  errorUpdateDetails: {
    id: 'cc4.bank-details-error-msg',
    defaultMessage: "Couldn't update your details, please try again.",
  },
  thankYouTitle: {
    id: 'cc4.bank-details-thank-you-title',
    defaultMessage: 'Thank you.',
  },
  saveAndReview: {
    id: 'cc4.bank-details-save-and-review-button',
    defaultMessage: 'Save and review',
  },
  saveOnly: {
    id: 'cc4.bank-details-save-only-button',
    defaultMessage: 'Or save only',
  },
  shareNewsLabel: {
    id: 'cc4.bank-details-share-news-label',
    defaultMessage: 'Share the good news on',
  },
  flightNumberLabel: {
    id: 'cc4.bank-details-flight-number-label',
    defaultMessage: 'Flight number',
  },
  flightDateLabel: {
    id: 'cc4.bank-details-flight-date-label',
    defaultMessage: 'Flight date',
  },
  showMoreLabel: {
    id: 'cc4.bank-details-show-more-label',
    defaultMessage: 'Show more',
  },
  showLessLabel: {
    id: 'cc4.bank-details-show-less-label',
    defaultMessage: 'Show less',
  },
  passengersLabel: {
    id: 'cc4.bank-details-compensation-passengers-label',
    defaultMessage: 'Compensation for passengers',
  },
  totalLabel: {
    id: 'cc4.bank-details-total-label',
    defaultMessage: 'Total',
  },
  feesLabel: {
    id: 'cc4.bank-details-fees-label',
    defaultMessage: 'Our fee',
  },
  perPassengerLabel: {
    id: 'cc4.bank-details-per-passenger-label',
    defaultMessage: 'per passenger',
  },
  willReceiveLabel: {
    id: 'cc4.bank-details-will-receive-label',
    defaultMessage: 'You will receive',
  },
  dataProtectionLabel: {
    id: 'cc4.bank-details-flight-data-protection-label',
    defaultMessage: 'Your personal data is protected and secured.',
  },
});
